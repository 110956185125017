import { render, staticRenderFns } from "./UserSignup.vue?vue&type=template&id=ef2b7988&scoped=true"
import script from "./UserSignup.vue?vue&type=script&lang=js"
export * from "./UserSignup.vue?vue&type=script&lang=js"
import style0 from "./UserSignup.vue?vue&type=style&index=0&id=ef2b7988&prod&lang=scss&scoped=true"
import style1 from "./UserSignup.vue?vue&type=style&index=1&id=ef2b7988&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef2b7988",
  null
  
)

export default component.exports